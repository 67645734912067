import { apiCall } from 'lib/api-client'
import { JSONRPCMethods } from 'types/json-rpc.types'
import {
  CreateIndexRequest,
  CreateIndexResponse,
  IndexVectorRequest,
  IndexVectorResponse,
  ListIndexesRequest,
  ListIndexesResponse,
  QueryIndexRequest,
  QueryIndexResponse,
} from '../types'

export function createIndexApi(values: CreateIndexRequest) {
  return apiCall<CreateIndexRequest, CreateIndexResponse>(JSONRPCMethods.CREATE_INDEX, values, {
    useApiKey: true,
  })
}

export function queryIndexApi(values: QueryIndexRequest) {
  return apiCall<QueryIndexRequest, QueryIndexResponse>(JSONRPCMethods.QUERY, values)
}

export function indexVectorApi(values: IndexVectorRequest) {
  return apiCall<IndexVectorRequest, IndexVectorResponse>(JSONRPCMethods.INSERT, values)
}

export function listIndexesApi() {
  return apiCall<ListIndexesRequest, ListIndexesResponse>(
    JSONRPCMethods.LIST_INDEXES,
    {},
    {
      useAuthKey: true,
    }
  )
}
