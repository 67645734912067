import { createBrowserRouter } from 'react-router-dom'

import authRoutes from 'modules/auth/routes'
import indexRoutes from 'modules/indexes/routes'

import NotFound from 'components/not-found'
import Root from './root'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      children: [
        ...authRoutes,
        ...indexRoutes,
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ],
  {
    basename: '/console',
  }
)

export default router
