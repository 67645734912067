import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'

import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from 'components/ui/input-otp'

function VerificationPage() {
  return (
    <>
      <Heading level={1}>
        Verify Your Email
        <Text className="font-normal">Please enter the code you received in your email.</Text>
      </Heading>

      <form className="w-full">
        <InputOTP maxLength={6} className="justify-center">
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-16 text-xl" index={0} />
            <InputOTPSlot className="h-16 w-16 text-xl" index={1} />
            <InputOTPSlot className="h-16 w-16 text-xl" index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot className="h-16 w-16 text-xl" index={3} />
            <InputOTPSlot className="h-16 w-16 text-xl" index={4} />
            <InputOTPSlot className="h-16 w-16 text-xl" index={5} />
          </InputOTPGroup>
        </InputOTP>

        <Button className="mt-6 w-full">Verify My Email</Button>
        <Button className="mt-6 w-full font-normal" outline>
          Resend the code
        </Button>
      </form>
    </>
  )
}

export default VerificationPage
