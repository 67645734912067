export const MOCK_API_KEYS = [
  {
    id: '8bf232cc-d222-42f5-90be-46e035b09c7a',
    name: "Hamit's API Key",
    key: 'PZFDs7fY********************',
    index: 'hamits-index',
  },
  {
    id: '8bf232cc-d222-42f5-90be-46e035b09c7a',
    name: "Hamit's API Key",
    key: 'PZFDs7fY********************',
    index: 'hamits-index',
  },
  {
    id: '8bf232cc-d222-42f5-90be-46e035b09c7a',
    name: "Hamit's API Key",
    key: 'PZFDs7fY********************',
    index: 'hamits-index',
  },
]
