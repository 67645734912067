import { apiCall } from 'lib/api-client'
import { JSONRPCMethods } from 'types/json-rpc.types'
import { LoginRequest, LoginResponse, RefreshTokenRequest } from '../types'

export function loginApi(values: LoginRequest) {
  return apiCall<LoginRequest, LoginResponse>(JSONRPCMethods.LOGIN, values)
}

export function refreshTokenApi(values: RefreshTokenRequest) {
  return apiCall<RefreshTokenRequest, LoginResponse>(JSONRPCMethods.REFRESH_TOKEN, values)
}
