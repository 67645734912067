import { isLocalhost } from 'lib/utils'

export const isProdEnv = process.env.NODE_ENV === 'production'

const parsedUrl = typeof window !== 'undefined' ? new URL(window.location.origin) : ({} as any)

const appConfig = {
  API_BASE_URL: '',
  DOCS_BASE_URL: '',
  ADMIN_API_BASE_URL: '',
}

if (isLocalhost) {
  appConfig.API_BASE_URL = 'https://hamit.sandbox.vectroid.dev/api/v1/rpc'
  appConfig.ADMIN_API_BASE_URL = 'https://hamit.sandbox.vectroid.dev/admin/v1/rpc'
  appConfig.DOCS_BASE_URL = 'https://hamit.sandbox.vectroid.dev/docs'
} else {
  appConfig.API_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/api/v1/rpc`
  appConfig.ADMIN_API_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/admin/v1/rpc`
  appConfig.DOCS_BASE_URL = `${parsedUrl.protocol}//${parsedUrl.hostname}/docs`
}

export { appConfig }
