import { useState } from 'react'
import { Button } from './catalyst/button'

type Props = {
  text: string
  className?: string
}

export function CopyButton(props: Props) {
  const [btnText, setBtnText] = useState('Copy')

  function handleCopy() {
    navigator?.clipboard?.writeText(props.text)

    setBtnText('Copied!')
    setTimeout(() => {
      setBtnText('Copy')
    }, 2000)
  }

  return (
    <Button outline onClick={handleCopy} className={props.className}>
      {btnText}
    </Button>
  )
}
