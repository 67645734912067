import { Heading } from 'components/catalyst/heading'
import { IndexMetrics } from 'modules/indexes/components/index-metrics'

function ConsolePage() {
  return (
    <>
      <Heading level={2} className="sm:text-lg/8" divider={false}>
        Metrics
      </Heading>

      <IndexMetrics />
    </>
  )
}

export default ConsolePage
