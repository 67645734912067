import { Button } from 'components/catalyst/button'
import { Field, Label } from 'components/catalyst/fieldset'
import { Heading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Link } from 'components/catalyst/link'
import { Text } from 'components/catalyst/text'
import { RouteNames } from 'const/navigation'

function ResetPasswordPage() {
  return (
    <>
      <Heading level={1}>Reset Password</Heading>

      <form className="w-full space-y-6">
        <Field>
          <Label>Email</Label>
          <Input name="email" />
        </Field>

        <Button className="w-full">Reset My Password</Button>

        <Text className="text-center">
          <b className="font-semibold text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300">
            <Link to={RouteNames.Login}>Sign In</Link>
          </b>
          {' or '}
          <b className="font-semibold text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300">
            <Link to={RouteNames.Register}>Create an account</Link>
          </b>
        </Text>
      </form>
    </>
  )
}

export default ResetPasswordPage
