import { Avatar } from 'components/catalyst/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from 'components/catalyst/sidebar'
import { SidebarLayout } from 'components/catalyst/sidebar-layout'
import { SidebarMenuItems } from 'const/sidebar'
import { useTheme } from 'context/theme-provider'
import { cn, getInitials } from 'lib/utils'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  HelpCircle as QuestionMarkCircleIcon,
  Lightbulb as LightBulbIcon,
  LogOut as ArrowRightStartOnRectangleIcon,
  PlusIcon,
  Settings as Cog8ToothIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserCircle2Icon,
} from 'lucide-react'
import { useAuthStore } from 'modules/auth'
import { useLocation } from 'react-router-dom'
import { AuthLayout } from './auth-layout'
import { CommandBar } from './command-bar'
import { NotificationBanner } from './common/notification-banner'
import { Logo } from './logo'
import { ThemeToggle } from './theme-toggle'

function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
  const logout = useAuthStore((state) => state.logout)

  function handleLogout() {
    logout()
  }

  return (
    <DropdownMenu className="min-w-64" anchor={anchor}>
      <DropdownItem href="#">
        <UserCircle2Icon size={16} className="mr-3" />
        <DropdownLabel>My account</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem href="#">
        <ShieldCheckIcon size={16} className="mr-3" />
        <DropdownLabel>Privacy policy</DropdownLabel>
      </DropdownItem>
      <DropdownItem href="#">
        <LightBulbIcon size={16} className="mr-3" />
        <DropdownLabel>Share feedback</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem onClick={handleLogout}>
        <ArrowRightStartOnRectangleIcon size={16} className="mr-3" />
        <DropdownLabel>Sign out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  )
}

function ApplicationLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation()
  const { sidebarExpanded } = useTheme()
  const account = useAuthStore((state) => state.account)

  const renderMenuItems = () => {
    return SidebarMenuItems.map((menuItems, index) => {
      return (
        <div key={`SidebarSection-${index}`}>
          {index > 0 && <SidebarDivider />}
          <SidebarSection>
            {menuItems.map((menuItem) => (
              <SidebarItem href={menuItem.href} current={pathname === menuItem.href} key={menuItem.text}>
                {menuItem.icon}
                {sidebarExpanded && <SidebarLabel>{menuItem.text}</SidebarLabel>}
              </SidebarItem>
            ))}
          </SidebarSection>
        </div>
      )
    })
  }

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  src={account?.picture}
                  initials={getInitials(account?.name)}
                  className="size-10"
                  square
                  alt=""
                />
              </DropdownButton>
              <AccountDropdownMenu anchor="bottom end" />
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar className="relative z-10">
          <SidebarHeader>
            <span className="mb-6 block">
              <Logo />
            </span>

            <Dropdown>
              <DropdownButton as={SidebarItem} href={'#'}>
                <Avatar initials="ST" className="bg-purple-500 text-white" />
                {sidebarExpanded && (
                  <>
                    <SidebarLabel className="flex-1">SpaceX Team</SidebarLabel>
                    <ChevronDownIcon size={16} />
                  </>
                )}
              </DropdownButton>
              <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                <DropdownItem href="/settings">
                  <Cog8ToothIcon size={16} />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <Avatar slot="icon" src="/images/vectroid.svg" />
                  <DropdownLabel>Vectroid Inc.</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="#">
                  <Avatar slot="icon" initials="SP" className="bg-purple-500 text-white" />
                  <DropdownLabel>SpaceX Team</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="#">
                  <PlusIcon size={16} />
                  <DropdownLabel>New team&hellip;</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarHeader>

          <SidebarBody>
            <SidebarSection>
              <CommandBar />
            </SidebarSection>

            <SidebarDivider />
            <SidebarSection>{renderMenuItems()}</SidebarSection>

            <SidebarSpacer />

            <SidebarSection>
              <SidebarItem href="#">
                <QuestionMarkCircleIcon size={16} />
                {sidebarExpanded && <SidebarLabel>Support</SidebarLabel>}
              </SidebarItem>

              <SidebarItem href="#">
                <SparklesIcon size={16} />
                {sidebarExpanded && <SidebarLabel>Changelog</SidebarLabel>}
              </SidebarItem>

              <ThemeToggle />
            </SidebarSection>
          </SidebarBody>

          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem} href="#" className={cn(!sidebarExpanded ? '-mx-1' : '')}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar
                    src={account?.picture}
                    initials={getInitials(account?.name)}
                    className="size-10"
                    square
                    alt=""
                  />
                  {sidebarExpanded && (
                    <span className="min-w-0">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                        {account?.name}
                      </span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {account?.email}
                      </span>
                    </span>
                  )}
                </span>
                {sidebarExpanded && <ChevronUpIcon size={16} />}
              </DropdownButton>
              <AccountDropdownMenu anchor="top start" />
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <NotificationBanner />

      {children}
    </SidebarLayout>
  )
}

function ApplicationLayoutHOC(props: { children: React.ReactNode; isAuthenticated: boolean }) {
  if (props.isAuthenticated) {
    return <ApplicationLayout>{props.children}</ApplicationLayout>
  }

  return <AuthLayout>{props.children}</AuthLayout>
}

export default ApplicationLayoutHOC
