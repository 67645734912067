import { RouteNames } from 'const/navigation'
import { Link } from 'react-router-dom'

export function IndexListEmptyState() {
  return (
    <Link
      to={RouteNames.CreateIndex}
      type="button"
      className="relative mt-6 block w-full rounded-lg border-2 border-dashed border-neutral-300 p-12 text-center hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-500"
    >
      <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 48 48"
        aria-hidden="true"
        className="mx-auto h-12 w-12 dark:text-neutral-300"
      >
        <path
          d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="mt-4 block text-sm font-medium text-neutral-700 dark:text-neutral-500">
        You don't have any indexes yet
      </span>
      <span className="mt-2 block text-sm font-medium text-neutral-700 dark:text-neutral-500">Create a new one</span>
    </Link>
  )
}
