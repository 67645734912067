import { RouteNames } from 'const/navigation'

import Login from './pages/login'
import RegisterPage from './pages/register'
import ResetPasswordPage from './pages/reset-password'
import VerificationPage from './pages/verification'
import AuthCallBackPage from './pages/auth-callback'

const routes = [
  {
    path: RouteNames.Login,
    element: <Login />,
  },
  {
    path: RouteNames.Register,
    element: <RegisterPage />,
  },
  {
    path: RouteNames.ResetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: RouteNames.Verification,
    element: <VerificationPage />,
  },
  {
    path: RouteNames.GoogleSSOCallback,
    element: <AuthCallBackPage />,
  },
]

export default routes
