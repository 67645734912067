import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { RouteNames } from 'const/navigation'
import { Index } from 'modules/indexes'
import { Link } from 'react-router-dom'

type Props = {
  index: Index
}

export function IndexItemGrid(props: Props) {
  return (
    <Link to={RouteNames.IndexDetail.get(props.index.name)} className="group">
      <Card className="max-w-[520px] space-y-3">
        <span className="flex items-center text-xs">
          <span className="mr-1.5 block h-1.5 w-1.5 rounded-full bg-emerald-400" />
          {props.index.region}
        </span>

        <div className="-mx-4">
          <img src={`${process.env.PUBLIC_URL}/images/mock/chart.svg`} className="w-full" alt="chart" />
        </div>

        <div className="flex items-center">
          <Text className="flex-1 font-semibold text-zinc-900 transition-all group-hover:text-sky-600 dark:text-zinc-300 group-hover:dark:text-sky-400 sm:text-base">
            {props.index.name}
          </Text>

          <Text className="flex-1 text-right dark:text-zinc-500 group-hover:dark:text-zinc-400 sm:text-xs">
            0.17 queries/s
          </Text>
        </div>

        <Text className="text-xs sm:text-xs">Last update: 15min. ago</Text>
      </Card>
    </Link>
  )
}
