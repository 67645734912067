import CodeEditor from '@uiw/react-textarea-code-editor'
import { Button } from 'components/catalyst/button'
import { Card } from 'components/catalyst/card'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { Text } from 'components/catalyst/text'
import { CopyButton } from 'components/copy-button'
import { CodeSkeleton } from 'components/skeletons'
import { useTheme } from 'context/theme-provider'
import { cn, ctrlKey } from 'lib/utils'
import { ChevronRight } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type Props = {
  onExecute: (code: string) => void
  loading: boolean
  result: string
  code?: string
}

export enum ConsoleActionTabs {
  Query = 'query',
  Insert = 'insert',
}

const editorStyles = {
  backgroundColor: 'transparent',
  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
  fontSize: 13,
  lineHeight: 2,
}

export function QueryConsole(props: Props) {
  const [code, setCode] = useState(props.code ?? '')
  const [isFocused, setIsFocused] = useState(false)
  const editorRef = useRef()

  const [searchParams, setSearchParams] = useSearchParams()

  const tab: ConsoleActionTabs = (searchParams.get('action') as ConsoleActionTabs) || ConsoleActionTabs.Query

  const { theme } = useTheme()

  const executeCode = () => {
    try {
      // @ts-ignore
      editorRef?.current?.setAttribute('disabled', 'true')

      props.onExecute(code)

      setTimeout(() => {
        // @ts-ignore
        editorRef?.current?.removeAttribute('disabled')
        // @ts-ignore
        editorRef?.current?.focus()
      }, 300)
    } catch (error) {
      console.log(error)
    }
  }

  const onKeyDown = (e: any) => {
    if (e.metaKey && e.which === 13) {
      e.preventDefault()
      executeCode()
      return false
    }
  }

  function handleCardClick() {
    // @ts-ignore
    editorRef?.current?.focus()
  }

  function handleTabClick(newTab: ConsoleActionTabs) {
    setSearchParams({ action: newTab })
  }

  function handlePrettifyCode() {
    try {
      const prettifiedCode = JSON.stringify(JSON.parse(code), null, 3)
      setCode(prettifiedCode)
    } catch (error) {
      console.log(error)
    }
  }

  function renderCodeEditorResult() {
    if (props.loading) {
      return <CodeSkeleton />
    }

    if (props.result) {
      return (
        <CodeEditor
          value={props.result}
          language="json"
          disabled
          style={{ ...editorStyles }}
          data-color-mode={theme as 'light' | 'dark'}
        />
      )
    }

    return (
      <Text className="opacity-50">
        Results will appear here. <span className="text-base">{ctrlKey}</span>+ Enter to execute
      </Text>
    )
  }

  useEffect(() => {
    setCode(props.code ?? '')
  }, [props.code])

  return (
    <div className="mt-2 flex w-full max-sm:block max-sm:space-y-6 sm:space-x-6">
      <div className="flex-1" tabIndex={0}>
        <Navbar className="">
          <NavbarSection>
            <NavbarItem
              className="flex-1"
              onClick={() => handleTabClick(ConsoleActionTabs.Query)}
              current={tab === ConsoleActionTabs.Query}
            >
              Query
            </NavbarItem>
            <NavbarItem
              className="w-full"
              onClick={() => handleTabClick(ConsoleActionTabs.Insert)}
              current={tab === ConsoleActionTabs.Insert}
            >
              Insert
            </NavbarItem>
          </NavbarSection>
          <NavbarSpacer />
        </Navbar>

        <div onClick={handleCardClick} className="cursor-text">
          <Card
            className={cn(
              'group relative flex h-auto flex-col p-4 ring-amber-900/50 ring-offset-2 ring-offset-transparent transition-all',
              {
                'ring-2': isFocused,
              }
            )}
          >
            <ChevronRight className="absolute left-2.5 top-7 mt-0.5" size={16} />

            <div className="mb-4 flex-1 rounded-lg pl-2">
              <Button
                outline
                onClick={handlePrettifyCode}
                className="!absolute right-4 top-4 z-20 inline-block w-auto opacity-40"
              >
                Prettify
              </Button>

              <CodeEditor
                autoFocus
                value={code}
                language="json"
                placeholder=""
                onChange={(evn) => setCode(evn.target.value)}
                style={editorStyles}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={onKeyDown}
                data-color-mode={theme as 'light' | 'dark'}
                // @ts-ignore
                ref={editorRef}
              />
            </div>

            <div className="mt-6 flex items-center justify-end">
              <kbd className="800 pointer-events-none mr-2 inline-flex h-5 select-none items-center gap-1 rounded-sm px-1.5 font-mono text-xs font-medium text-amber-600 opacity-100 dark:text-amber-100 max-lg:hidden">
                <span className="text-base">{ctrlKey}</span>+ Enter
              </kbd>
              <Button color="amber" onClick={executeCode}>
                Execute
              </Button>
            </div>
          </Card>
        </div>
      </div>

      <Card className="flex- group relative h-auto flex-1 sm:mt-14">
        {props.result && (
          <CopyButton
            className="!absolute right-4 top-4 z-20 inline-block !w-auto opacity-0 transition-opacity group-hover:opacity-100"
            text={props.result}
          />
        )}

        <div className="max-h-[calc(100vh_-_425px)] overflow-y-scroll">{renderCodeEditorResult()}</div>
      </Card>
    </div>
  )
}
