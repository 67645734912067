import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { appConfig } from 'const/app-config'
import { useAuthStore } from 'modules/auth'
import { refreshTokenApi } from 'modules/auth/services'
import { JSONRPCMethods, JSONRPCRequest, JSONRPCResponse } from 'types/json-rpc.types'
import { handleGoogleSSOLogin } from '../modules/auth/utils/google-sso'

export interface ApiResponse<T> {
  data: T
  status: number
}

export interface ApiError {
  message: string
  status: number
}

interface ApiCallOptions {}

const axiosClient = axios.create()

axiosClient.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}

    const token = useAuthStore?.getState()?.token
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`
    }

    const apiKey = useAuthStore?.getState()?.apiKey
    if (apiKey) {
      // @ts-ignore
      config.data.params[0].apiKey = apiKey
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // TODO: Read refresh token request from headers
    const errorMessage = error.response.data?.message

    if (errorMessage.includes('Refresh token is requested')) {
      try {
        const _refreshToken = useAuthStore?.getState()?.refreshToken
        if (_refreshToken) {
          // Refresh token
          const response = await refreshTokenApi({ refreshToken: _refreshToken })
          const { token, refreshToken, apiKey, account } = response.data
          useAuthStore?.getState().login(token, refreshToken, apiKey, account)

          // Retry the original request
          return axiosClient.request(error.config)
        }
      } catch (e) {
        console.error('Error while refreshing token:', e)
      }
    }

    if (errorMessage.includes('Invalid JWT token')) {
      const authMethod = useAuthStore?.getState()?.account?.authMethod
      useAuthStore?.getState().logout()
      if (authMethod === 'google') {
        handleGoogleSSOLogin()
      } else if (authMethod === 'email') {
        window.location.replace('/console/login')
      }

      return
    }

    console.log('Error:', error.response)
    return Promise.reject(error)
  }
)

/**
 * @param url {String}
 * @param method {Method}
 * @param data T
 * @param options {ApiCallOptions} - Options for the API call
 * @returns {Promise<ApiResponse<T>>}
 */
export async function apiCall<T, K>(
  method: JSONRPCMethods,
  data: T,
  options: ApiCallOptions = {}
): Promise<ApiResponse<K>> {
  const JSONRPCData: JSONRPCRequest<T> = {
    method,
    params: [data],
  }

  const config: AxiosRequestConfig<JSONRPCRequest<T>> = {
    baseURL: appConfig.ADMIN_API_BASE_URL,
    method: 'POST',
    data: JSONRPCData,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const response: AxiosResponse<JSONRPCResponse<K>> = await axiosClient(config)
    return { data: response.data.result, status: response.status }
  } catch (error: any) {
    const status = error?.response?.status || 500
    const message = error?.response?.data?.message || 'An unexpected error occurred'
    throw { message, status } as ApiError
  }
}
