import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table'
import { EllipsisVertical } from 'lucide-react'
import { MOCK_API_KEYS } from 'mocks'

export function ApiKeysTable() {
  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader>NAME</TableHeader>
            <TableHeader>API KEY</TableHeader>
            <TableHeader>INDEX</TableHeader>
            <TableHeader>ACTIONS</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {MOCK_API_KEYS.map((item) => (
            <TableRow key={item.id}>
              <TableCell className="font-medium">
                <code>{item.id}</code>
              </TableCell>
              <TableCell className="text-zinc-500">{item.name}</TableCell>
              <TableCell>
                <Badge>
                  <code>{item.key}</code>
                </Badge>
              </TableCell>
              <TableCell className="text-zinc-500">{item.index}</TableCell>
              <TableCell className="space-x-4">
                <Dropdown>
                  <DropdownButton plain>
                    <EllipsisVertical size={16} />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem href="/users/1">View</DropdownItem>
                    <DropdownItem href="/users/1/edit">Edit</DropdownItem>
                    <DropdownDivider />
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}
