import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { RouteNames } from 'const/navigation'
import { useDocsPanel } from 'context/docs-provider'
import { FileCode2 } from 'lucide-react'

export function IndexesHeader() {
  const { openDocsPanel } = useDocsPanel()

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
            <Button className="max-sm:w-full" color="amber" href={RouteNames.CreateIndex}>
              Create New Index
            </Button>
          </>
        }
        description="An index organizes and holds multiple vectors in Vectroid."
      >
        Indexes
      </Heading>
    </>
  )
}
