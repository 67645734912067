import { ThemeProvider } from 'context/theme-provider'

import ApplicationLayout from 'components/app-layout'
import ErrorBoundaryFallback from 'components/error-boundary'
import { SuspenseLoader } from 'components/suspense-loader'
import { AUTH_ROUTES, RouteNames } from 'const/navigation'
import { DocsPanelProvider } from 'context/docs-provider'
import { useAuthStore } from 'modules/auth'
import { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export default function Root() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const isAuthRoute = AUTH_ROUTES.includes(location.pathname)
    const attemptedPath = location.state?.from?.pathname

    if (!isAuthenticated && !isAuthRoute) {
      // Redirect to login if not authenticated and not on an auth route
      navigate(RouteNames.Login, { state: { from: location } })
    } else if (isAuthenticated && isAuthRoute) {
      // Redirect to dashboard if authenticated and on an auth route
      navigate(RouteNames.Dashboard)
    } else if (isAuthenticated && attemptedPath && attemptedPath !== location.pathname) {
      // Navigate back to the attempted route if authenticated
      navigate(attemptedPath)
    }
  }, [isAuthenticated, navigate, location])

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <ThemeProvider defaultTheme="dark" storageKey="vectroid-theme">
          <DocsPanelProvider>
            <ApplicationLayout isAuthenticated={isAuthenticated}>
              <Outlet />
            </ApplicationLayout>
          </DocsPanelProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
