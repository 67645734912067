import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Banner } from 'components/banner'
import { RouteNames } from 'const/navigation'
import { IndexDetailHeader } from 'modules/indexes/components/index-detail-header'
import { IndexDetailContext } from 'modules/indexes/context'
import { useIndexStore } from 'modules/indexes/store'

function IndexDetailPage() {
  const params = useParams()
  const { getIndexByName, loading: indexLoading, fetchIndexes } = useIndexStore()
  const indexDetail = getIndexByName(params.indexName!)
  const navigate = useNavigate()

  const viewDashboard = () => {
    navigate(RouteNames.Dashboard)
  }

  useEffect(() => {
    fetchIndexes()
  }, [])

  if (indexLoading) {
    return <Banner type="loading" message="Loading..." />
  }

  if (!indexDetail) {
    return (
      <Banner type="error" message="Index not found" actionBtns={[{ label: 'Go Dashboard', onClick: viewDashboard }]} />
    )
  }

  return (
    <IndexDetailContext.Provider value={indexDetail}>
      <IndexDetailHeader />

      <div className="mt-6">
        <Outlet />
      </div>
    </IndexDetailContext.Provider>
  )
}

export default IndexDetailPage
