import { Input } from 'components/catalyst/input'
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox'
import { DashboardLayout, useTheme } from 'context/theme-provider'
import { ActivityIcon, ArrowDownAzIcon, LayoutGridIcon, ListIcon } from 'lucide-react'

export function IndexesHeaderToolbar() {
  const { dashboardLayout, setDashboardLayout } = useTheme()

  return (
    <div className="mb-6 grid grid-cols-2 gap-6 sm:grid-cols-3">
      <Input
        name="search"
        placeholder="Search&hellip;"
        aria-label="Search indexes..."
        className="col-span-1 sm:col-span-2"
      />

      <div className="col-span-2 grid grid-cols-2 gap-x-6 sm:col-span-1">
        <Listbox name="status" defaultValue="activity">
          <ListboxOption value="activity">
            <ActivityIcon size={16} />
            <ListboxLabel>Sort by activity</ListboxLabel>
          </ListboxOption>
          <ListboxOption value="name">
            <ArrowDownAzIcon size={16} />
            <ListboxLabel>Sort by name</ListboxLabel>
          </ListboxOption>
        </Listbox>

        <Listbox name="status" defaultValue={dashboardLayout} onChange={(value) => setDashboardLayout(value)}>
          <ListboxOption className="w-32" value={DashboardLayout.Stacked}>
            <ListIcon size={16} />
            <ListboxLabel>Stacked layout</ListboxLabel>
          </ListboxOption>
          <ListboxOption className="w-32" value={DashboardLayout.Grid}>
            <LayoutGridIcon size={16} />
            <ListboxLabel>Grid layout</ListboxLabel>
          </ListboxOption>
        </Listbox>
      </div>
    </div>
  )
}
