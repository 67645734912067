import { Heading } from 'components/catalyst/heading'
import { ApiKeyCreateDialog } from 'modules/indexes/components/api-key-create-dialog'
import { ApiKeysTable } from 'modules/indexes/components/api-keys-table'

function ConsolePage() {
  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        description="Manage your API-keys and access to your index."
        divider={false}
        actions={
          <>
            <ApiKeyCreateDialog />
          </>
        }
      >
        API Keys
      </Heading>

      <ApiKeysTable />
    </>
  )
}

export default ConsolePage
