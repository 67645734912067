import { Navigate, RouteObject } from 'react-router-dom'

import { RouteNames } from 'const/navigation'

import CreateIndex from './pages/create-index'
import Indexes from './pages/index'
import IndexDetail from './pages/index-detail'

// Index Detail sub routes
import ApiKeys from './pages/index-detail/api-keys'
import ConsolePage from './pages/index-detail/console'
import Metrics from './pages/index-detail/metrics'

const routes: RouteObject[] = [
  {
    path: RouteNames.Indexes,
    element: <Indexes />,
  },
  {
    path: RouteNames.CreateIndex,
    element: <CreateIndex />,
  },
  {
    path: RouteNames.IndexDetail.name,
    element: <IndexDetail />,
    children: [
      {
        index: true,
        element: <Navigate to={RouteNames.IndexDetailConsole.name} replace />,
      },
      {
        path: RouteNames.IndexDetailConsole.name,
        element: <ConsolePage />,
      },
      {
        path: RouteNames.IndexDetailAccessDB.name,
        element: <div>IndexDetailAccessDB page</div>,
      },
      {
        path: RouteNames.IndexDetailMetrics.name,
        element: <Metrics />,
      },
      {
        path: RouteNames.IndexDetailAPIKeys.name,
        element: <ApiKeys />,
      },
      {
        path: RouteNames.IndexDetailConfiguration.name,
        element: <div>IndexDetailConfiguration page</div>,
      },
      {
        path: RouteNames.IndexDetailLogs.name,
        element: <div>IndexDetailLogs page</div>,
      },
      {
        path: RouteNames.IndexDetailSettings.name,
        element: <div>IndexDetailSettings page</div>,
      },
    ],
  },
]

export default routes
