import { FileCode2, LayersIcon } from 'lucide-react'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'

import { RouteNames } from 'const/navigation'
import { useDocsPanel } from 'context/docs-provider'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IndexDetailContext } from '../context'

const IndexDetailTabs = [
  { label: 'Console', href: RouteNames.IndexDetailConsole.name },
  { label: 'Access Database', href: RouteNames.IndexDetailAccessDB.name },
  { label: 'Metrics', href: RouteNames.IndexDetailMetrics.name },
  { label: 'API Keys', href: RouteNames.IndexDetailAPIKeys.name },
  { label: 'Configuration', href: RouteNames.IndexDetailConfiguration.name },
  { label: 'Logs', href: RouteNames.IndexDetailLogs.name },
  { label: 'Settings', href: RouteNames.IndexDetailSettings.name },
]

export function IndexDetailHeader() {
  const indexDetail = useContext(IndexDetailContext)

  const { openDocsPanel } = useDocsPanel()
  const { pathname } = useLocation()

  function showDocs() {
    openDocsPanel('/query/#query-a-vector')
  }

  const tabs = IndexDetailTabs.map((tab) => ({
    ...tab,
    current: pathname.includes(tab.href),
  }))

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
          </>
        }
        tabs={tabs}
      >
        <div className="flex items-center space-x-4">
          <LayersIcon className="text-sky-600 dark:text-sky-400" />
          <span>{indexDetail.name}</span>
        </div>
      </Heading>
    </>
  )
}
