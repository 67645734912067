import { IndexDetailInfo } from 'modules/indexes/components/index-detail-info'
import { ConsoleActionTabs, QueryConsole } from 'modules/indexes/components/query-console'
import { indexVectorApi, queryIndexApi } from 'modules/indexes/services/index.services'
import { IndexVectorRequest, IndexVectorResponse, QueryIndexRequest, QueryIndexResponse } from 'modules/indexes/types'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

type IndexRequestType = QueryIndexRequest | IndexVectorRequest
type IndexResponseType = QueryIndexResponse | IndexVectorResponse

function ConsolePage() {
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [result, setResult] = useState<any>(null)

  const tab: ConsoleActionTabs = (searchParams.get('action') as ConsoleActionTabs) || ConsoleActionTabs.Query

  function setResponse(data: IndexResponseType) {
    setResult(JSON.stringify(data, null, 3))
  }

  const onExecute = async (code: string) => {
    try {
      setLoading(true)

      switch (tab) {
        case ConsoleActionTabs.Query: {
          const parsedCode = JSON.parse(code) as QueryIndexRequest
          const response = await queryIndexApi(parsedCode)
          setResponse(response.data)
          break
        }

        case ConsoleActionTabs.Insert: {
          const parsedCode = JSON.parse(code) as IndexVectorRequest
          const response = await indexVectorApi(parsedCode)
          setResponse(response.data)
          break
        }
      }
    } catch (e: any) {
      console.log(e)
      setResponse(e)
    } finally {
      setLoading(false)
    }
  }

  const getInitialCode = () => {
    const action = searchParams.get('action')

    let code: IndexRequestType = {} as IndexRequestType

    if (tab === ConsoleActionTabs.Insert) {
      code = {
        indexName: params.indexName!,
        id: '1',
        vector: [0.1, 0.2, 0.3],
      }
    }

    if (tab === ConsoleActionTabs.Query || !action) {
      code = {
        indexName: params.indexName!,
        k: 10,
        vector: [0.1, 0.2, 0.3],
      }
    }

    return JSON.stringify(code, null, 3)
  }

  return (
    <>
      <IndexDetailInfo />
      <QueryConsole onExecute={onExecute} loading={loading} result={result} code={getInitialCode()} />
    </>
  )
}

export default ConsolePage
