import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { CreateAccountFormSchema } from '../schema'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Text } from 'components/catalyst/text'
import { Alert, AlertDescription } from 'components/ui/alert'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SocialLogins } from '../components/social-logins'
import { createAccountApi } from '../services'
import { useAuthStore } from '../store'

const formSchema = CreateAccountFormSchema

const formFields = [
  { name: 'email', label: 'Email', autoFocus: true, type: 'email' },
  { name: 'name', label: 'Full Name' },
  { name: 'password', label: 'Password', type: 'password' },
]

function RegisterPage() {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setLoading(true)

      const response = await createAccountApi(values)
      const { token, refreshToken, apiKey, account } = response.data

      loginAction(token, refreshToken, apiKey, account)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      console.log(e)
      setErrorMessage(e?.message ?? GENERIC_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  function renderForm() {
    return formFields.map(({ name, label, ...formField }) => (
      <FormField
        key={name}
        control={form.control}
        name={name as any}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{label}</FormLabel>

            <FormControl>
              <Input disabled={loading} {...field} {...formField} />
            </FormControl>

            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    ))
  }

  return (
    <>
      <Heading level={1}>Create an Account</Heading>
      <Text>
        Already have an account?{' '}
        <b className="font-normal text-sky-600 hover:text-sky-700 dark:text-sky-400 dark:hover:text-zinc-300">
          <Link to={RouteNames.Login}>Sign in.</Link>
        </b>
      </Text>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          {renderForm()}

          {errorMessage && (
            <Alert variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={loading} disabled={loading}>
            Create My Account
          </Button>

          <SocialLogins />
        </form>
      </Form>
    </>
  )
}

export default RegisterPage
