import { cn } from 'lib/utils'
import { Divider } from './divider'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './navbar'
import { Text } from './text'

type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  actions?: React.ReactNode
  description?: string
  tabs?: { label: string; href: string; current: boolean }[]
  divider?: boolean
} & React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>

export function Heading({ className, actions, tabs, description, level = 1, divider = true, ...props }: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`

  function renderTabs() {
    if (tabs?.length) {
      return (
        <>
          <Navbar className="mt-4">
            <NavbarSection>
              {tabs?.map((tab) => (
                <NavbarItem href={tab.href} current={tab.current} key={tab.href}>
                  {tab.label}
                </NavbarItem>
              ))}
            </NavbarSection>
            <NavbarSpacer />
          </Navbar>
          <Divider />
        </>
      )
    }

    if (divider) {
      return <Divider className="my-6" />
    }

    return <div className="my-6" />
  }

  return (
    <>
      <div className="flex items-center justify-between gap-4 max-sm:block">
        <div className="flex-1 max-sm:mb-4">
          <Element
            {...props}
            className={cn('text-2xl/8 font-semibold text-zinc-950 dark:text-white sm:text-xl/8', className)}
          />

          {description && <Text className="text-sm">{description}</Text>}
        </div>
        <div className="flex justify-end gap-6">{actions}</div>
      </div>

      {renderTabs()}
    </>
  )
}

export function Subheading({ className, level = 2, ...props }: HeadingProps) {
  let Element: `h${typeof level}` = `h${level}`

  return (
    <Element
      {...props}
      className={cn('text-base/7 font-semibold text-zinc-950 dark:text-white sm:text-sm/6', className)}
    />
  )
}
